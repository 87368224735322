






































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Chart, ChartResult, TableChartResult, User } from '@/models'
import Loading from '@/components/Loading.vue'
import chartTypes from './chartTypes'
import gql from 'graphql-tag'
import CollectionWatcher from '@/components/tools/CollectionWatcher.vue'
import { ApolloError } from 'apollo-client'

interface FilterValue {
  filterId ?: string
  filterOptions : Record<string, any>
}

@Component({
  components: {
    Loading,
    CollectionWatcher
  },
  apollo: {
    result: {
      query: gql`query getChartResult($chartId: ID, $filterId: ID, $filterOptions: JSON, $params: JSON, $queryOptions: JSON, $previewOptions: JSON) {
        result: chartResult(
          chartId: $chartId
          filterId: $filterId
          filterOptions: $filterOptions
          params: $params
          queryOptions: $queryOptions
          previewOptions: $previewOptions
        )
      }`,
      fetchPolicy: 'network-only',
      variables () {
        return {
          chartId: this.chart._id,
          filterId: this.filterId,
          filterOptions: this.filterOptions,
          params: this.viewParams,
          queryOptions: this.queryOptions,
          previewOptions: this.previewOptions
        }
      },
      error (e : ApolloError) {
        this.error = e
      }
    }
  }
})
export default class ChartViewResult extends Vue {
  @Prop({ type: Object, required: true }) chart !: Chart
  @Prop({ type: Boolean, default: false }) preview !: boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams !: Record<string, any>
  @Prop({ type: String }) filterId !: string
  @Prop({ type: Object }) filterOptions !: Record<string, any>
  @Prop({ type: Object }) previewOptions ?: Record<string, any>
  @Prop({ type: Boolean }) exportable ?: boolean

  result : ChartResult | null = null
  error : ApolloError | null = null
  queryOptions = {}

  get renderComponent () {
    return this.result && this.result.renderType && chartTypes[this.result.renderType]
  }

  async update () {
    await this.$apollo.queries.result.refetch()
  }

  @Watch('result')
  checkExportable (result : TableChartResult) {
    if (!result) return
    this.$emit('update:exportable', result.renderType === 'table' && result.table.exportable)
  }

  get errorMessage () {
    if (!this.error) return ''
    return this.error.graphQLErrors.map((gqlError : any) => {
      let errStr = gqlError.message
      if (gqlError.validationErrors) {
        errStr += ':'
        const invalidFields = Object.keys(gqlError.validationErrors)
        errStr += invalidFields.map(f => `\n  - ${f}: ${gqlError.validationErrors[f]}`)
      }
      return errStr
    }).join('\n')
  }

  get user () : User {
    return this.$store.state.auth.user
  }

  get isAdmin () {
    return this.user.roles.indexOf('admin') >= 0 || this.user.roles.indexOf('superAdmin') >= 0
  }
}
